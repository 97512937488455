import { appendUTM } from '@doist/fullstack-utils'
import { BlogCard, Heading, Text } from '@doist/marketist'

import { SectionContainer } from 'components/layout'

import css from './article-section.module.css'

export function ArticlesSection() {
    const appendPageUTM = (link: string) => appendUTM(link, { utm_campaign: 'home' })
    return (
        <SectionContainer section padded className={css.articlesSection}>
            <div className={css.descriptionContainer}>
                <Heading level="3" size="major-base">
                    We’re bootstrapped, profitable, and here for the long-run
                </Heading>
                <Text size="body-lg">
                    We’re an independent company without plans to sell out or get acquired. We’ll be
                    here for our users and our teammates for decades to come.
                </Text>
            </div>

            <div className={css.articlesContainer}>
                <BlogCard
                    href={appendPageUTM('https://blog.doist.com/no-exit-strategy/')}
                    src={'/static/images/home-page/articles-section/exit-strategy@2x.jpg'}
                    alt=""
                    title="Why We Don’t Have an Exit Strategy"
                >
                    Unlike most of today&apos;s high-growth tech startups, we want to be the owners
                    of our destiny and build a meaningful company that will outlast us. Not sell out
                    to the highest bidder.
                </BlogCard>

                <BlogCard
                    href={appendPageUTM(
                        'https://blog.doist.com/how-to-become-a-market-leader-in-10-years/',
                    )}
                    src="/static/images/home-page/articles-section/market-leader@2x.jpg"
                    alt=""
                    title="How to become a market leader in 10 years"
                >
                    We’ve learned a thing or two about building a sustainable company in a
                    competitive market with millions of users and zero investors.
                </BlogCard>
            </div>
        </SectionContainer>
    )
}
