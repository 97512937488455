import { appendUTM } from '@doist/fullstack-utils'
import { Button, Hairline, Heading, HyperLink, Image, Text, TwistLogo } from '@doist/marketist'

import { SectionContainer } from 'components'
import { LogoTD } from 'components/icons'

import css from './product-section.module.css'

const TODOIST_LINK = appendUTM('https://todoist.com/home', { utm_campaign: 'homepage' })
const TWIST_LINK = appendUTM('https://twist.com/home', { utm_campaign: 'homepage' })

function ProductsSection() {
    return (
        <div className={css.productsSection}>
            <Image
                className={css.illustrations}
                src="/static/images/home-page/product-section/illustration-product-section@2x.jpg"
                width={1800}
                height={884}
                alt=""
            />

            <SectionContainer padded section className={css.contents}>
                <Heading level="2" size="major-lg">
                    And challenging the status quo with simple yet powerful tools
                </Heading>

                <div className={css.productsContainer}>
                    <div className="todoistTheme">
                        <Hairline />
                        <HyperLink
                            className={css.productLogo}
                            target="_blank"
                            href={TODOIST_LINK}
                            aria-label="See Todoist"
                        >
                            <LogoTD />
                        </HyperLink>
                        <Text size="body-lg">
                            Todoist: The top-ranked productivity app that brings clarity and
                            efficiency to millions of people and teams.
                        </Text>
                        <Button size="large" target="_blank" href={TODOIST_LINK}>
                            Learn more
                        </Button>
                    </div>
                    <div className="twistTheme">
                        <Hairline />
                        <HyperLink
                            className={css.productLogo}
                            target="_blank"
                            href={TWIST_LINK}
                            aria-label="See Twist"
                        >
                            <TwistLogo />
                        </HyperLink>
                        <Text size="body-lg">
                            The only asynchronous-first collaboration app that encourages teams to
                            focus, create, and disconnect.
                        </Text>
                        <Button size="large" target="_blank" href={TWIST_LINK}>
                            Learn more
                        </Button>
                    </div>
                </div>
            </SectionContainer>
        </div>
    )
}

export default ProductsSection
