import { appendUTM } from '@doist/fullstack-utils'
import { Espa, Heading, Image, Link, Text } from '@doist/marketist'

import { SectionContainer } from 'components'

import css from './map-section.module.css'

const BLOG_LINK = 'https://blog.doist.com/remote-first-workplaces//'

function MapSection() {
    return (
        <div className={css.mapSection}>
            <SectionContainer padded section>
                <div className={css.textContainer}>
                    <Heading level="2" size="major-lg">
                        Pioneering async- and remote-first work for over a decade
                    </Heading>

                    <Text size="body-lg" className={css.subtitle}>
                        Doist has been async- and remote-first since day one (back in 2010). We’re
                        living proof that{' '}
                        <Link size="body-lg" href={appendUTM(BLOG_LINK)} target="_blank">
                            this way of working is the future
                        </Link>
                        , not a fad.
                    </Text>
                </div>
                <Image
                    width={1665}
                    height={716}
                    src="/static/images/home-page/map-section/world_map.png"
                    alt=""
                    className={css.map}
                />

                <div className={css.numbersContainer}>
                    <Image
                        className={css.mobileStatsDeco}
                        width={600}
                        height={391}
                        src="/static/images/home-page/map-section/illustration-home-numbers@2x.jpg"
                        alt=""
                    />
                    <div className={css.copies}>
                        <Espa tag="h4" size="espa-xl">
                            2007
                        </Espa>
                        <Text size="body-sm">year founded</Text>
                    </div>
                    <Image
                        className={`${css.yellow} ${css.perceptionalLine}`}
                        src="/static/images/home-page/map-section/line-yellow@2x.jpg"
                        width={66}
                        height={7}
                        alt=""
                    />
                    <div className={css.copies}>
                        <Espa tag="h4" size="espa-xl">
                            97%
                        </Espa>
                        <Text size="body-sm">employee retention rate</Text>
                    </div>
                    <Image
                        className={`${css.red} ${css.perceptionalLine}`}
                        src="/static/images/home-page/map-section/line-red@2x.jpg"
                        width={61}
                        height={7}
                        alt=""
                    />
                    <div className={css.copies}>
                        <Espa tag="h4" size="espa-xl">
                            50%
                        </Espa>
                        <Text size="body-sm"> of Doisters have been here 4+ years</Text>
                    </div>
                    <Image
                        className={`${css.blue} ${css.perceptionalLine}`}
                        src="/static/images/home-page/map-section/line-blue@2x.jpg"
                        width={56}
                        height={7}
                        alt=""
                    />
                    <div className={css.copies}>
                        <Espa tag="h4" size="espa-xl">
                            $0
                        </Espa>
                        <Text size="body-sm">VC funds raised</Text>
                    </div>
                </div>
            </SectionContainer>
        </div>
    )
}

export default MapSection
