import { Heading, Image } from '@doist/marketist'

import cn from 'clsx'
import { SectionContainer } from 'components/layout'

import css from './hero-section.module.css'

export function HeroSection() {
    return (
        <div className={css.heroSection}>
            <SectionContainer padded heroSection className={css.heroText}>
                <Heading level="1" className={css.heading} size="major-3xl">
                    Creating global impact by simplifying life’s complexities
                </Heading>
            </SectionContainer>
            <Image
                width={1368}
                height={500}
                src="/static/images/home-page/hero-section/homepage_illus.jpg"
                alt=""
                className={cn(css.fullWidth, css.illustration)}
                priority
            />
        </div>
    )
}
